html,
body {
  height: 100%;
  width: 100%;

  & * {
    font-family: 'Nunito', sans-serif;
  }
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

// fix for react-icons alignment, without interfering with ant icons alignment
*:not(i) > svg {
  vertical-align: middle;
}
