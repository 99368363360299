/* COMMON STYLE REDOX */
.container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.table > tbody > tr {
  > td,
  > th {
    border-top: none;
  }
}

hr {
  border-width: 1px;
  max-width: 50px;

  &.light {
    border-color: #1b1d1c;
  }

  &.lighting {
    border-color: white;
  }
}

p {
  font-size: 16px;
  line-height: 1.5;

  a {
    font-weight: 500;
  }
}

a {
  &,
  &:focus,
  &:hover {
    text-decoration: none;
    color: #29c086;
  }
}

.shadow {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.text-faded {
  color: rgba(255, 255, 255, 0.7);
}

::selection {
  color: white;
  background: #1b1d1c;
}

img::selection {
  color: white;
  background: transparent;
}

img::-moz-selection {
  color: white;
  background: transparent;
}
