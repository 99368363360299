/* HELPER CLASS NAMES */

.bold {
  font-weight: bold !important;
}

.boldish {
  font-weight: 500 !important;
}

.italic {
  font-style: italic !important;
  color: #fc0d1c;
}

.pointer {
  cursor: pointer !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-justify {
  text-align: justify !important;
}

.fixed {
  position: fixed !important;
}

.color-green {
  color: #29c086;
}

.inline-block {
  display: inline-block !important;
}

.inline-flex {
  display: inline-flex !important;
}
