/* MULTIPLE FILES CSS */

// TEXT FORMATTING
.green34pxLight {
  color: #35bf87;
  font-weight: 300;
  font-size: 34px;
}
.white34pxLight {
  color: #ffffff;
  font-weight: 300;
  font-size: 34px;
}
.white28pxLight {
  color: #ffffff;
  font-weight: 300;
  font-size: 28px;
}
.white14pxRegular {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}
.grey16pxRegular {
  color: #777777;
  font-weight: 400;
  font-size: 16px;
}
.grey14pxRegular {
  color: #777777;
  font-weight: 400;
  font-size: 14px;
}
.strongGrey24pxRegular {
  color: #4c5257;
  font-weight: 400;
  font-size: 24px;
}
.strongGrey16pxRegular {
  color: #4c5257;
  font-weight: 400;
  font-size: 16px;
}
/* GUIDES */

.guide h3 {
  margin-top: 0;
}

.app-steps p {
  font-size: 18px;
}

.steps p {
  font-size: 18px;
}

.group {
  text-align: justify;

  p {
    padding-top: 20px;
  }
}

/* sidebar */

.bs-docs-sidebar {
  margin-top: 20px;
  float: right;
  z-index: 999; /* In front of footer */

  .nav {
    > li > a {
      color: #999;
      border-left: 2px solid transparent;
      padding: 4px 20px;
      font-size: 14px;
      font-weight: 400;
      width: 20vw;
    }

    > .active > a,
    > li > a:hover,
    > li > a:focus {
      color: #29c086;
      text-decoration: none;
      background-color: transparent;
      border-left-color: #29c086;
    }

    > .active > a,
    > .active:hover > a,
    > .active:focus > a {
      font-weight: bold;
    }
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

/* all links */

.terms-privacy {
  white-space: pre-line;
  text-align: justify;
}

.box-item {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 0 1 350px;
  padding: 5px;
  border: 1px solid #1b1d1c;
  border-radius: 1em;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
